var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.sessions, function (session, idx) {
      return _c("div", { key: `session_${idx}`, staticClass: "col-md-6" }, [
        _c(
          "div",
          {
            staticClass: "kt-portlet",
            class: !session.current
              ? "kt-portlet--border-bottom-warning"
              : "kt-portlet--border-bottom-success",
          },
          [
            _c(
              "div",
              { staticClass: "kt-portlet__body kt-portlet__body--fluid" },
              [
                _c("div", { staticClass: "kt-widget26" }, [
                  _c("div", { staticClass: "kt-widget26__content" }, [
                    _c("span", { staticClass: "kt-widget26__number" }, [
                      _vm._v(" " + _vm._s(session.ipAddress) + " "),
                      session.current
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "kt-badge kt-badge--sm pt-2 pb-2 kt-badge--success kt-badge--inline pull-right",
                            },
                            [_vm._v(" ACTIVE SESSION ")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-label-danger btn-sm btn-bold session-kill-button pull-right",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.killSession(session)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "la la-close" }),
                              _vm._v(" Delete Session "),
                            ]
                          ),
                    ]),
                    _c("span", { staticClass: "kt-widget26__desc" }, [
                      _vm._v(" " + _vm._s(session.platform) + " "),
                    ]),
                    session.current
                      ? _c("span", { staticClass: "kt-widget26__desc" }, [
                          _vm._v(
                            " " +
                              _vm._s(session.authMethod) +
                              ", active session "
                          ),
                        ])
                      : _c("span", { staticClass: "kt-widget26__desc" }, [
                          _vm._v(
                            " " +
                              _vm._s(session.authMethod) +
                              ", seen " +
                              _vm._s(session.lastSeen) +
                              " "
                          ),
                        ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }