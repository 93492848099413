<template>
<div class="row">
    <div
        v-for="(session, idx) in sessions"
        :key="`session_${idx}`"
        class="col-md-6"
    >
        <div
            class="kt-portlet"
            :class="!session.current ? 'kt-portlet--border-bottom-warning' : 'kt-portlet--border-bottom-success'"
        >
            <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget26">
                    <div class="kt-widget26__content">
                        <span class="kt-widget26__number">
                            {{ session.ipAddress }}

                            <span
                                v-if="session.current"
                                class="kt-badge kt-badge--sm pt-2 pb-2 kt-badge--success kt-badge--inline pull-right"
                            >
                                ACTIVE SESSION
                            </span>
                            <button
                                v-else
                                class="btn btn-label-danger btn-sm btn-bold session-kill-button pull-right"
                                @click.stop.prevent="killSession(session)"
                            >
                                <i class="la la-close" />
                                Delete Session
                            </button>
                        </span>
                        <span class="kt-widget26__desc">
                            {{ session.platform }}
                        </span>
                        <span
                            v-if="session.current"
                            class="kt-widget26__desc"
                        >
                            {{ session.authMethod }}, active session
                        </span>
                        <span
                            v-else
                            class="kt-widget26__desc"
                        >
                            {{ session.authMethod }}, seen {{ session.lastSeen }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import * as network from '../../network';

export default {
    name: 'AccountSessions',
    components: {

    },
    data() {
        return {
            sessions: [],
        };
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;
            network.session.listActiveSessions({}, (err, result) => {
                if (err) return v.showError(err);
                v.sessions = result.sessions;
            });
        },
        killSession(session) {
            if (session.current) return;
            const jsonSessionId = session.identifier;
            const v = this;
            const params = {
                body: { jsonSessionId },
            };
            network.session.deleteSession(params, (err) => {
                if (err) return v.showError(err);
                const sessions = v.sessions.filter((s) => s.identifier != jsonSessionId);
                v.sessions = sessions;
            });
        },
    },
};
</script>
